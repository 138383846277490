.yellow-bg {
  background-color: #3d9970  !important;
  /* background-color: #fff599 !important; */
}
.yellow-bg div h4,.yellow-bg div ul li a,.yellow-bg div i{
  color: white
}

.pink-bg {
  background-color: #00bfff  !important;
  /* background-color: #ffced7 !important; */
}
.pink-bg div h4,.pink-bg div ul li a,.pink-bg div i{
  color: white
}


.green-bg {
  background-color: #52527a !important;
  /* background-color: #dcf9da !important; */
}
.red-bg {
  background-color: #ff8686 !important;
  /* background-color: #dcf9da !important; */
}
.red-bg div h4,.red-bg div ul li a,.red-bg div i{
  color: white
}

.green-bg div h4,.green-bg div ul li a,.green-bg div i{
  color: white
}

.leave0tool{
  min-width: 182px !important;
}
.kpi0tool{
  min-width: 219px !important;
}

.brown-bg {
  background-color: #72777A !important;
  /* background-color: #ffebcf !important; */
}
.orange-bg {
  background-color: #f7941e !important;
  /* background-color: #ffebcf !important; */
}
.brown-bg div h4,.brown-bg div ul li a,.brown-bg div i{
  color: white
}


.dui-a {
  color: #585858;
  display: inline-block;
  width: 100%;

}
.dashboard-item .card .card-body ul.shortcut-menu li:hover{
  background-color: #d5d5d554;
  border-radius: 1rem;
}


.shortcut-menu{
  padding-left:0rem !important;
}

.orange-bg {
  background-color: #db740d !important;
  /* background-color: #ffebcf !important; */
}
.orange-bg div h4,.orange-bg div ul li a,.orange-bg div i{
  color: white
}



.prussian-bg {
  background-color: #13334d  !important;
  /* background-color: #fff599 !important; */
}

.mango-bg {
  background-color: #ecb834  !important;
  /* background-color: #fff599 !important; */
}

.windsor-bg {
  background-color: #ad5726  !important;
  /* background-color: #fff599 !important; */
}

.spotblack-bg {
  background-color: #000000  !important;
  /* background-color: #fff599 !important; */
}

.umberburnt-bg {
  background-color: #1d1d8e  !important;
  background-color: #8e281d  !important;
  /* background-color: #fff599 !important; */
}
.navyblue-bg{
  background-color: #0B3B6D !important;
  color: #fff;
}
.navyblue-bg h4{
  color: #fff;
}
.dui-am {
  color: #ffffff;
  display: inline-block;
  width: 100%;


}
.moment{
  border: 4px solid #938787 !important;
}
.moment .card-header h4{
  color: #ffffff;
}
.dashui .card-header i{
  color: #ffffff;
}
.moment .card-body ul.shortcut-menu li:hover{
  background-color: #d5d5d5 !important;
  border-radius: 1rem;
}
.moment .card-body ul.shortcut-menu li:hover a .nav-text-drop{
  color: black;
}